<template>
  <div class="mt-4">
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-data-table
        v-model="selected"
        color="primary"
        class="elevation-0 pa-0"
        mobile-breakpoint="950"
        disable-sort
        item-key="project_id"
        :headers="headers"
        :items="projectTableData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading-text="$t('loading')"
      >
        <template v-slot:[`item.project_name`]="{ item }">
          <v-row class="my-auto text-subtitle-2 font-weight-bold ma-0">
            <!-- <a
              class="primary--text text-decoration-none"
              @click="$emit('onClickProject', item)"
              >{{ item.project_name }}</a
            > -->
            <router-link
              class="primary--text text-decoration-none"
              :to="'/projectDetails?pid=' + item.project_id"
              style="color: #1976d2; white-space: nowrap"
            >
              {{ item.project_name }}
            </router-link>
          </v-row>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-select
            v-if="checkPermission('editProject')"
            v-model="item.status"
            :items="projectStatusList"
            :menu-props="{ bottom: true, offsetY: true }"
            solo
            flat
            dense
            @change="changeProjectStatus(item)"
            hide-details
          >
          </v-select>
          <span v-else>{{ item.status }}</span>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <v-progress-linear
            :height="12"
            width="100"
            rounded
            bottom
            :buffer-value="100"
            color="green"
            :value="item.progress"
          >
            <span class="text-caption font-weight-bold"
              >{{ item.progress }}%</span
            >
          </v-progress-linear>
        </template>
        <template v-slot:[`item.tasks`]="{ item }">
          {{ item.tasks }}
        </template>
        <template v-slot:[`item.project_owner`]="{ item }">
          {{ item.project_owner }}
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          {{ item.start_date }}
        </template>
        <template v-slot:[`item.finish_date`]="{ item }">
          {{ item.finish_date }}
        </template>
        <template v-slot:[`item.no_of_attachments`]="{ item }">
          <span @click="showUploadFiles(item)"
            ><v-icon left class="mr-1"> mdi-paperclip </v-icon
            ><span class="text-subtitle-2 font-weight-bold">
              <a class="primary--text text-decoration-none">
                {{ item.no_of_attachments }} Files</a
              ></span
            ></span
          >
        </template>
        <template
          v-if="checkPermission('editProject')"
          v-slot:[`item.edit`]="{ item }"
        >
          <v-icon
            class="mr-2"
            aria-label="Edit"
            :disabled="item.status === 'Inactive'"
            @click="onEditProjectClicked(item)"
          >
            mdi-pencil-outline
          </v-icon>
        </template>
        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.project_id">
              <v-card height="auto" elevation="0">
                <v-row no-gutters justify="space-between">
                  <v-col cols="10" class="ml-1 mt-2 mb-1 py-0">
                    <span class="text-subtitle-2 font-weight-bold">
                      <router-link
                        class="primary--text text-decoration-none"
                        :to="'/projectDetails?pid=' + item.project_id"
                        style="color: #1976d2; white-space: nowrap"
                      >
                        {{ item.project_name }}
                      </router-link></span
                    >
                  </v-col>
                  <v-col
                    v-if="checkPermission('editProject')"
                    cols="1"
                    class="ml-2 mt-2 text-right"
                  >
                    <v-icon
                      class="mr-2"
                      aria-label="Edit"
                      :disabled="item.status === 'Inactive'"
                      @click="onEditProjectClicked(item)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" class="mt-2 mb-2">
                    <v-progress-linear
                      :height="14"
                      width="100"
                      rounded
                      bottom
                      :buffer-value="100"
                      color="green"
                      :value="item.progress"
                    >
                      <span class="text-caption font-weight-bold"
                        >{{ item.progress }}%</span
                      >
                    </v-progress-linear>
                  </v-col>
                </v-row>
                <v-row class="ml-1 mt-1 mb-2">
                  <v-col
                    cols="6"
                    class="pa-0 my-auto text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("status") }}:</v-col
                  >
                  <v-col
                    class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                    ><v-select
                      v-if="checkPermission('editProject')"
                      class="mr-3"
                      max-width="60px"
                      v-model="item.status"
                      :items="projectStatusList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      outlined
                      rounded
                      dense
                      flat
                      @change="changeProjectStatus(item)"
                      hide-details
                    >
                    </v-select>
                    <span class="d-flex justify-end pr-4" v-else>{{
                      item.status
                    }}</span>
                  </v-col>
                </v-row>
                <v-row class="ml-1 my-2"
                  ><v-col
                    cols="7"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("tasks") }}:</v-col
                  ><v-col
                    class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                  >
                    {{ item.tasks }}</v-col
                  ></v-row
                >
                <v-row class="ml-1 my-2"
                  ><v-col
                    cols="7"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("projectOwner") }}:</v-col
                  ><v-col
                    class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                  >
                    {{ item.project_owner }}</v-col
                  ></v-row
                >
                <v-row class="ml-1 my-2"
                  ><v-col
                    cols="7"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("startDate") }}:</v-col
                  ><v-col
                    class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                  >
                    {{ item.start_date }}</v-col
                  ></v-row
                >
                <v-row class="ml-1 my-2"
                  ><v-col
                    cols="7"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("latestFinishDate") }}:</v-col
                  ><v-col
                    class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                  >
                    {{ item.finish_date }}</v-col
                  ></v-row
                >
                <v-row class="ml-1 my-2 mb-5"
                  ><v-col
                    cols="7"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("attachments") }}:</v-col
                  ><v-col
                    class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                    ><span @click="showUploadFiles(item)"
                      ><v-icon left class="mr-1"> mdi-paperclip </v-icon>
                      <span class="text-subtitle-2 font-weight-bold">
                        <a class="primary--text text-decoration-none">
                          {{ item.no_of_attachments }} File</a
                        ></span
                      ></span
                    >
                  </v-col></v-row
                >
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <div
      v-if="$vuetify.breakpoint.mdAndUp && isUploadFiles"
      id="addProjectPanel"
    >
      <UploadFiles
        :reference-id="editItem.project_id"
        @hideUploadFiles="hideUploadFiles"
      />
    </div>
    <v-dialog v-else v-model="isUploadFiles" fullscreen>
      <UploadFiles
        :reference-id="editItem.project_id"
        @hideUploadFiles="hideUploadFiles"
      />
    </v-dialog>
    <v-layout>
      <div
        v-if="$vuetify.breakpoint.mdAndUp && addProjectDialog"
        id="addProjectPanel"
      >
        <AddProject
          ref="addProject"
          :project-details="editItem"
          @hideProjectDialog="hideProjectDialog"
        />
      </div>
      <v-dialog
        v-if="!$vuetify.breakpoint.mdAndUp && addProjectDialog"
        v-model="addProjectDialog"
        fullscreen
      >
        <AddProject
          ref="addProject"
          :project-details="editItem"
          @hideProjectDialog="hideProjectDialog"
        />
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import UserDataMixin from "@/mixins/UserData";
export default {
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  mixins: [UserDataMixin],
  components: {
    AddProject: () => import("./AddProject.vue"),
    UploadFiles: () => import("./UploadFiles.vue"),
  },
  data() {
    return {
      selected: [],
      projectTableData: [],
      addProjectDialog: false,
      editItem: {},
      projectStatusList: [
        "Planning",
        "In Progress",
        "On Hold",
        "Closed",
        "Inactive",
      ],
      isUploadFiles: false,
    };
  },
  created() {
    this.getProjectData();
  },
  computed: {
    headers() {
      const header = [
        {
          text: "Name",
          value: "project_name",
          width: "20%",
        },
        { text: "Status", value: "status", width: "15%" },

        { text: "Progress", value: "progress", width: "20%" },
        { text: "Tasks", value: "tasks" },
        { text: "Project Owner", value: "project_owner", width: "10%" },
        { text: "Start Date", value: "start_date" },
        { text: "Latest Finish Date", value: "finish_date" },
        { text: "Attachments", value: "no_of_attachments", width: "10%" },
      ];
      if (this.checkPermission("editProject")) {
        header.push({ text: "Edit", value: "edit" });
        return header;
      } else {
        return header;
      }
    },
    usersCompany() {
      return this.$store.state.User.companyUserMap;
    },
    projectData() {
      return this.$store.state.Project.project;
    },
    companyCurrentProjectCount() {
      let projectArray = this.$store.state.Project.project;
      let activeProject = projectArray.filter((item) => {
        return item.status !== "Inactive";
      });
      return activeProject.length;
    },
  },
  watch: {
    usersCompany() {
      this.getProjectData();
    },
    projectData() {
      this.getProjectData();
    },
  },
  methods: {
    async changeProjectStatus(project) {
      const projectPayload = {};
      projectPayload.project_id = project.project_id;
      projectPayload.status = project.status;
      if (project.status === "Inactive") {
        let currentCount = parseInt(this.companyCurrentProjectCount + 1, 10);
        let maxCount = parseInt(
          this.$store.state.Company.company.project_count,
          10
        );
        if (maxCount <= currentCount && maxCount > -1) {
          return;
        }
      }
      const projectUpdateResponse = await this.$store.dispatch(
        "updateProject",
        projectPayload
      );
      if (projectUpdateResponse.status === "error") {
        console.log("error");
      } else {
        this.$store.commit("setProjectData", this.projectTableData);
      }
    },
    getProjectData() {
      if (this.projectData && this.projectData.length !== 0) {
        this.projectTableData = this.projectData.map((ele) => ({ ...ele }));
        this.projectTableData.forEach((item) => {
          let tempDate = new Date(item.start_date);
          item.finish_date = item.max_task_end_date
            ? item.max_task_end_date
            : tempDate.setDate(tempDate.getDate() + 25);
          item.start_date = this.formatDate(item.start_date);
          item.finish_date =
            item.max_task_end_date || item.max_task_start_date
              ? new Date(item.max_task_end_date) >
                new Date(item.max_task_start_date)
                ? this.formatDate(item.max_task_end_date)
                : this.formatDate(item.max_task_start_date)
              : "-";
          if (this.usersCompany[item.project_manager_id])
            item.project_owner =
              this.usersCompany[item.project_manager_id].name;
        });
      }
    },
    hideProjectDialog() {
      this.addProjectDialog = false;
    },
    onEditProjectClicked(item) {
      if (item.status === "Inactive") return;
      this.editItem = item;
      this.addProjectDialog = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("T")[0].split("-");
      return month ? `${month}/${day}/${year}` : date;
    },
    hideUploadFiles() {
      this.isUploadFiles = false;
    },
    showUploadFiles(item) {
      this.editItem = item;
      this.isUploadFiles = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
