var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "v-card",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
          attrs: { elevation: "0", width: "100%" },
        },
        [
          _c("v-data-table", {
            staticClass: "elevation-0 pa-0",
            class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
            attrs: {
              color: "primary",
              "mobile-breakpoint": "950",
              "disable-sort": "",
              "item-key": "project_id",
              headers: _vm.headers,
              items: _vm.projectTableData,
              "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
              search: _vm.search,
              "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
              "loading-text": _vm.$t("loading"),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.project_name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "my-auto text-subtitle-2 font-weight-bold ma-0",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "primary--text text-decoration-none",
                              staticStyle: {
                                color: "#1976d2",
                                "white-space": "nowrap",
                              },
                              attrs: {
                                to: "/projectDetails?pid=" + item.project_id,
                              },
                            },
                            [_vm._v(" " + _vm._s(item.project_name) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.status",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm.checkPermission("editProject")
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.projectStatusList,
                              "menu-props": { bottom: true, offsetY: true },
                              solo: "",
                              flat: "",
                              dense: "",
                              "hide-details": "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeProjectStatus(item)
                              },
                            },
                            model: {
                              value: item.status,
                              callback: function ($$v) {
                                _vm.$set(item, "status", $$v)
                              },
                              expression: "item.status",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(item.status))]),
                    ]
                  },
                },
                {
                  key: "item.progress",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: 12,
                            width: "100",
                            rounded: "",
                            bottom: "",
                            "buffer-value": 100,
                            color: "green",
                            value: item.progress,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-caption font-weight-bold" },
                            [_vm._v(_vm._s(item.progress) + "%")]
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.tasks",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.tasks) + " ")]
                  },
                },
                {
                  key: "item.project_owner",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.project_owner) + " ")]
                  },
                },
                {
                  key: "item.start_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.start_date) + " ")]
                  },
                },
                {
                  key: "item.finish_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.finish_date) + " ")]
                  },
                },
                {
                  key: "item.no_of_attachments",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.showUploadFiles(item)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { left: "" } },
                            [_vm._v(" mdi-paperclip ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "text-subtitle-2 font-weight-bold" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "primary--text text-decoration-none",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.no_of_attachments) +
                                      " Files"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                _vm.checkPermission("editProject")
                  ? {
                      key: "item.edit",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "aria-label": "Edit",
                                disabled: item.status === "Inactive",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onEditProjectClicked(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-pencil-outline ")]
                          ),
                        ]
                      },
                    }
                  : null,
                !_vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "body",
                      fn: function (ref) {
                        var items = ref.items
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c(
                                "tr",
                                { key: item.project_id },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: { height: "auto", elevation: "0" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "space-between",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ml-1 mt-2 mb-1 py-0",
                                              attrs: { cols: "10" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "primary--text text-decoration-none",
                                                      staticStyle: {
                                                        color: "#1976d2",
                                                        "white-space": "nowrap",
                                                      },
                                                      attrs: {
                                                        to:
                                                          "/projectDetails?pid=" +
                                                          item.project_id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.project_name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm.checkPermission("editProject")
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "ml-2 mt-2 text-right",
                                                  attrs: { cols: "1" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "aria-label": "Edit",
                                                        disabled:
                                                          item.status ===
                                                          "Inactive",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onEditProjectClicked(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-pencil-outline "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-2 mb-2",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-progress-linear",
                                                {
                                                  attrs: {
                                                    height: 14,
                                                    width: "100",
                                                    rounded: "",
                                                    bottom: "",
                                                    "buffer-value": 100,
                                                    color: "green",
                                                    value: item.progress,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-caption font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.progress) +
                                                          "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 mt-1 mb-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 my-auto text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("status")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [
                                              _vm.checkPermission("editProject")
                                                ? _c("v-select", {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      "max-width": "60px",
                                                      items:
                                                        _vm.projectStatusList,
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      outlined: "",
                                                      rounded: "",
                                                      dense: "",
                                                      flat: "",
                                                      "hide-details": "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeProjectStatus(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.status",
                                                    },
                                                  })
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end pr-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.status)
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 my-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("tasks")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [_vm._v(" " + _vm._s(item.tasks))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 my-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("projectOwner")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.project_owner)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 my-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("startDate")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.start_date)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 my-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("latestFinishDate")
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.finish_date)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 my-2 mb-5" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("attachments")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showUploadFiles(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { left: "" },
                                                    },
                                                    [_vm._v(" mdi-paperclip ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-subtitle-2 font-weight-bold",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "primary--text text-decoration-none",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.no_of_attachments
                                                              ) +
                                                              " File"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.mdAndUp && _vm.isUploadFiles
        ? _c(
            "div",
            { attrs: { id: "addProjectPanel" } },
            [
              _c("UploadFiles", {
                attrs: { "reference-id": _vm.editItem.project_id },
                on: { hideUploadFiles: _vm.hideUploadFiles },
              }),
            ],
            1
          )
        : _c(
            "v-dialog",
            {
              attrs: { fullscreen: "" },
              model: {
                value: _vm.isUploadFiles,
                callback: function ($$v) {
                  _vm.isUploadFiles = $$v
                },
                expression: "isUploadFiles",
              },
            },
            [
              _c("UploadFiles", {
                attrs: { "reference-id": _vm.editItem.project_id },
                on: { hideUploadFiles: _vm.hideUploadFiles },
              }),
            ],
            1
          ),
      _c(
        "v-layout",
        [
          _vm.$vuetify.breakpoint.mdAndUp && _vm.addProjectDialog
            ? _c(
                "div",
                { attrs: { id: "addProjectPanel" } },
                [
                  _c("AddProject", {
                    ref: "addProject",
                    attrs: { "project-details": _vm.editItem },
                    on: { hideProjectDialog: _vm.hideProjectDialog },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.mdAndUp && _vm.addProjectDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "" },
                  model: {
                    value: _vm.addProjectDialog,
                    callback: function ($$v) {
                      _vm.addProjectDialog = $$v
                    },
                    expression: "addProjectDialog",
                  },
                },
                [
                  _c("AddProject", {
                    ref: "addProject",
                    attrs: { "project-details": _vm.editItem },
                    on: { hideProjectDialog: _vm.hideProjectDialog },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }